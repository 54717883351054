<template>
  <section>
    <div class="section" v-if="totalData > 0">
      <div class="table-container">
        <table class="table is-fullwidth" v-if="workerRating !== null">
          <thead>
            <tr>
              <th><abbr>Project ID Homecare</abbr></th>
              <th>Worker Name</th>
              <th><abbr title="Tanggal Pengerjaan">Date</abbr></th>
              <th><abbr title="Rating">Rating</abbr></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v, index) in workerRating" :key="index">
              <td>
                <span class="has-text-weight-bold">#{{ idhomecare }}</span>
              </td>
              <td>
                <article class="media">
                  <div class="media-left">
                    <figure class="image is-128x128 mb-3">
                      <img :src="v.worker_image" alt="Image">
                    </figure>
                  </div>
                  <div class="media-content">
                    <div class="content">
                      <p>
                        <strong>{{ v.worker_name }}</strong>
                        <br>
                        Worker ID : <small>{{ v.worker_id }}</small>
                      </p>
                    </div>
                  </div>
                </article>
              </td>
              <td>
                <span> {{ convDate(v.date) }} </span>
              </td>
              <td>
                <div class="columns mobile mt-1">
                  <ul v-if="v.star !== null">
                    <li>
                      <b-icon
                        v-for="v in v.star"
                        :key="v"
                        :class="v === numberStarRating ? '' : 'checkedRating'"
                        icon="star"
                        custom-size="mdi-24px">
                      </b-icon>
                    </li>
                  </ul>
                  <ul v-else>
                    <li>
                      <b-icon icon="star" custom-size="mdi-24px"></b-icon>
                      <b-icon icon="star" custom-size="mdi-24px"></b-icon>
                      <b-icon icon="star" custom-size="mdi-24px"></b-icon>
                      <b-icon icon="star" custom-size="mdi-24px"></b-icon>
                      <b-icon icon="star" custom-size="mdi-24px"></b-icon>
                    </li>
                  </ul>
                </div>
              </td>
              <td
                class="buttons is-centered"
                :class="v.star === null ? 'is-show' : 'is-hidden'"
                v-show="v.status_attendance > 0">
                <button
                  class="button is-primary is-outlined is-small is-responsive"
                  :class="v.date < validateDateSesi || v.date === validateDateSesi ? 'is-show' : 'is-hidden'"
                  @click="showBeriRating(v)">Beri Rating
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="totalData === 0" class="section">
      <div class="content has-text-grey has-text-centered">
        <template>
          <p>
            <b-icon icon="emoticon-sad" size="is-large"/>
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </div>

    <!-- MODAL BERIKAN RATING -->
    <div v-if="workerRating !== null">
      <b-modal
        v-model="modalPostRating"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        close-button-aria-label="Close"
        :can-cancel="false"
        aria-modal>
        <form action="">
          <div class="modal-card">
            <header class="modal-card-head">
              <h6 class="modal-card-title has-text-centered has-text-weight-bold">
                Proyek ID #{{ projectIds }}
              </h6>
              <button type="button" class="delete" @click="clearDataInput()"/>
            </header>
            <section class="modal-card-body">
              <div class="columns">
                <div class="column">
                  <div class="box" style="width: auto; height: 10rem;">
                    <article class="media">
                      <div class="media-left">
                        <figure class="image is-64x64 mb-3">
                          <img :src="profileImage" alt="Image">
                        </figure>
                      </div>
                      <div class="media-content">
                        <div class="content">
                          <p>
                            <strong>{{ nameSelect }}</strong>
                            <br>
                            Worker ID : <small>{{ workerIdSelect }}</small>
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div class="rating">
                    <ul class="list has-text-centered">
                      <li :key="star" v-for="star in maxStars" :class="{ 'active': star <= stars }" @click="rate(star)" class="star">
                        <b-icon scale="2" size="is-medium" :icon="star <= stars ? 'star' : 'star-outline'"/>
                      </li>
                    </ul>
                    <!-- <span v-if="hasCounter">{{ stars }} of {{ maxStars }}</span> -->
                  </div>
                  <div>
                    <textarea
                      class="textarea has-fixed-size"
                      v-model="comment"
                      placeholder="Tulis pendapat Anda mengenai Mitra kami">
                    </textarea>
                  </div>
                  <div class="mt-5">
                    <button
                      class="button is-success is-small is-responsive is-fullwidth"
                      @click="saveRating()"
                      :disabled="!isDisabled">KIRIM
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </form>
      </b-modal>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'RatingWorker',
  props: {
    projectIds: {
      type: Number,
      required: true
    },
    vendorIds: {
      type: String,
      required: true
    },
    statusProject: {
      type: String
    },
    // grade: {
    //   type: Number,
    //   required: true
    // },
    maxStars: {
      type: Number,
      default: 5
    },
    hasCounter: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      comment: null,
      stars: 0,
      workerIdSelect: null,
      nameSelect: null,
      modalPostRating: false,
      workerRating: null,
      profileImage: null,
      totalData: 0,
      datePengerjaan: null,
      workerByAttendance: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      attendances: 'attendance/getAttendances',
      idhomecare: 'attendance/getHomecareId'
    }),
    validateDateSesi () {
      const date = moment()
      const dateNow = date.format('YYYY-MM-DD')

      return dateNow
    },
    numberStarRating () {
      let isStar
      this.workerRating.forEach((element, i) => {
        isStar = element.star
      })
      let statusRating
      switch (isStar) {
        case 1:
          statusRating = '1'
          break
        case 2:
          statusRating = '2'
          break
        case 3:
          statusRating = '3'
          break
        case 4:
          statusRating = '4'
          break
        case 5:
          statusRating = '5'
          break

        default:
          statusRating = '0'
          break
      }
      return statusRating
    },
    validateButtonRating () {
      let isShowButton
      switch (this.statusProject) {
        case 'Active':
          isShowButton = 'true'
          break
        case 'Completed':
          isShowButton = 'true'
          break

        default:
          isShowButton = 'false'
          break
      }
      return isShowButton
    },
    isDisabled () {
      return (
        this.stars !== 0
      )
    }
  },
  methods: {
    clearDataInput () {
      this.workerIdSelect = null
      this.nameSelect = null
      this.datePengerjaan = null
      this.profileImage = null
      this.stars = 0
      this.comment = null
      this.modalPostRating = false
    },
    rate (star) {
      if (typeof star === 'number' && star <= this.maxStars && star >= 0) {
        this.stars = this.stars === star ? star - 1 : star
      }
    },
    showBeriRating (val) {
      if (val !== null) {
        this.workerIdSelect = val.worker_id
        this.nameSelect = val.worker_name
        this.datePengerjaan = val.date
        this.profileImage = val.worker_image
        this.modalPostRating = true
      }
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    async getWorkerRating () {
      try {
        const h = window.location.host

        let url
        if (h === 'shellb2b.devkanggo.com') {
          url = 'https://devapi.devkanggo.com'
        }
        if (h.substring(0, 9) === 'localhost') {
          url = 'https://devapi.devkanggo.com'
        }
        if (h === 'shellb2b.prodkanggo.com') {
          url = 'https://api.prodkanggo.com'
        }

        await axios.get(`${url}/api/v3/vendor/rating_worker/${this.projectIds}/${this.vendorIds}`)
          .then((cb) => {
            if (cb.data.code === 1) {
              this.workerRating = cb.data.data
              this.totalData = cb.data.data.length
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } catch (error) {
        throw new Error(error)
      }
    },
    async saveRating () {
      try {
        if (this.stars === 0) {
          this.$buefy.toast.open({
            duration: 4000,
            message: 'Rating Star tidak boleh kosong!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return false
        }

        if (this.comment === null) {
          this.$buefy.toast.open({
            duration: 4000,
            message: 'Comment tidak boleh kosong!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return false
        }

        if (this.workerIdSelect === null ||
            this.vendorIds === null || this.datePengerjaan === null) {
          this.$buefy.toast.open({
            duration: 4000,
            message: 'Data Tidak Boleh Kosong!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return
        }

        const data = {
          worker_id: this.workerIdSelect,
          project_id: this.projectIds,
          vendor_id: parseInt(this.vendorIds),
          star: this.stars,
          comment: this.comment || '',
          date: this.datePengerjaan
        }

        const h = window.location.host
        let url
        if (h === 'shellb2b.devkanggo.com') {
          url = 'https://devapi.devkanggo.com'
        }
        if (h.substring(0, 9) === 'localhost') {
          url = 'https://devapi.devkanggo.com'
        }
        if (h === 'shellb2b.prodkanggo.com') {
          url = 'https://api.prodkanggo.com'
        }

        await axios.post(`${url}/api/v3/vendor/rating_worker`, data)
          .then((cb) => {
            if (cb.data.code === 1) {
              this.$buefy.toast.open({
                duration: 4000,
                message: cb.data.message,
                position: 'is-bottom',
                type: 'is-success'
              })
              this.clearDataInput()
              this.getWorkerRating()
              this.modalPostRating = false
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } catch (error) {
        throw new Error(error)
      }
    }
  },
  mounted () {},
  created () {
    this.getWorkerRating()
  }
}
</script>

<style lang="scss" scoped>
  $active-color: #f3d23e;

.rating {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 22px;
  color: #a7a8a8;
  margin-top: 2rem;
}
.list {
  margin: 0 0 5px 0;
  padding: 0;
  list-style-type: none;
  &:hover {
    .star {
      color: $active-color;
    }
  }
}
.star {
  display: inline-block;
  cursor: pointer;
  &:hover {
    &~.star {
      &:not(.active) {
        color: inherit;
      }
    }
  }
}
.active {
  color: $active-color;
}
.checkedRating {
  color: $active-color;
}
.is-hidden {
  visibility: hidden;
}
.is-show {
  visibility: visible;
}
</style>

<!-- :class="{'is-show': validateButtonRating}" -->
<!-- 0 -> Not Present, 1 -> Present by Barcode, 2-> Present by Manual -->
