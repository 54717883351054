<template>
  <section>
    <!-- HEADER DETAIL -->
    <div class="card">
      <div class="card-content">
        <p @click="back()" class="back-btn title is-6" style="margin-bottom: 10px;"> <b-icon icon='arrow-left'/> Kembali ke proyek</p>
        <h1 style="padding-left: 10px;" class="title is-4">Detail Proyek - {{ title }} (#{{ this.$route.params.projectId }})</h1>
      </div>
    </div>

    <!-- TABS DETAIL PROJECT -->
    <div class="columns">
      <div class="column">
        <div class="tabs is-centered is-boxed">
          <ul>
            <li :class="{'is-active' : activeTab === 0}">
              <a @click="selectTab(0)">
                <span>Detail Project</span>
              </a>
            </li>
            <li :class="{'is-active' : activeTab === 1}">
              <a @click="selectTab(1)">
                <span>Worker Sign</span>
              </a>
            </li>
            <li :class="{'is-active' : activeTab === 4}">
              <a @click="selectTab(4)">
                <span>Attendance</span>
              </a>
            </li>
            <li :class="{'is-active' : activeTab === 2}">
              <a @click="selectTab(2)">
                <span>Before-After</span>
              </a>
            </li>
            <li :class="{'is-active' : activeTab === 3}">
              <a @click="selectTab(3)">
                <span>Rating Worker</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- DETAIL PROJECT -->
    <div class="columns is-gapless" ref="element">
      <div class="column">
        <div class="card" v-show="activeTab === 0">
          <div class="card-content" v-if="project !== null">
            <div class="columns">
              <div class="column" style="border-right: solid thin #f0f0f0;">
                <div class="columns is-mobile mb-4" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
                  <div class="column">
                    <h1 class="title is-5 mt-3">Proyek ID #{{ project.id }}</h1>
                  </div>
                  <div v-if="!roleAccess('ADMIN')">
                    <div class="column" v-bind:class="getClass()">
                      <b-button type="is-danger" @click="cancelOrder(project.id)">Batalkan Proyek</b-button>
                    </div>
                  </div>
                  <!-- <div class="column is-justify-content-flex-end mb-3" :class="{'is-invisible': project.invoice === null}">
                    <b-button type="is-warning has-text-left has-text-weight-bold" @click="setInvoice(project)">BAYAR</b-button>
                  </div> -->
                </div>
                <div class="columns is-mobile">
                  <div class="column is-8">
                    <div class="control" v-show="project.offer_id">
                      <div class="tags has-addons">
                        <span class="tag has-text-weight-bold">No.Penawaran</span>
                        <span class="tag is-info">{{ project.reference_code }}</span>
                      </div>
                    </div>
                    <br/>
                    <div>
                      <strong>
                        Nama Client: {{ project.company_name }} <br/>
                        Nama PIC: {{ project.consumer_name }} <br/>
                        Telp PIC: {{ project.consumer_phone }} <br/>
                      </strong>
                    </div>
                    <br/>
                    <div>
                      <strong>
                        Pesanan: Tukang Langsung <br/>
                        Pesanan Dibuat: {{ project.created_project }}<br/>
                        Tanggal Pembuatan Pesanan: <br/> {{ project.created_at }} <br/> <br/>
                        Status Proyek: <span class="tag" :class="tagStatus">{{ project.status }}</span>
                      </strong>
                    </div>
                    <br/>
                  </div>
                  <div class="garis_verikal"></div>
                  <div class="column">
                    <div class="mt-5">
                      <strong>Deskripsi Pekerjaan</strong>
                      <div> {{ project.description }} </div>
                    </div>
                  </div>
                </div>
                <div class="columns mt-4" style="border-top: solid thin #f0f0f0;">
                  <div class="column is-full">
                    <div class="columns" style="border-bottom: solid thin #f0f0f0;">
                      <div class="column is-12">
                        <div class="my-1" style="display: flex;">
                          <div style="width: 100%;">
                            <table class="table" style="width: 100%;">
                              <thead>
                                <tr>
                                  <td class="has-text-black">
                                    <div class="columns">
                                      <div class="column"><strong> Tanggal & Sesi </strong></div>
                                    </div>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(v, i) in listSesi" :key="'A'+ i">
                                  <td> <span>{{ convDate(v.dateSesi) }}</span>
                                    <ul v-for="(vv, i) in v.detail" :key="'B'+ i">
                                      <li><strong>{{ vv.dataWorker.name }}</strong>, {{ vv.name }} ( {{ vv.start_hour }} - {{ vv.end_hour }} )</li>
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="columns is-mobile">
                      <div class="column">
                        <p class="has-text-weight-medium">Estimasi Total Biaya</p>
                        <h1 class="title is-size-6 has-text-info">Rp.{{ parseInt(project.amount) | numFormat }}</h1>
                      </div>
                      <div class="column">
                        <p class="has-text-weight-medium">Total Bayar</p>
                        <h1 class="title is-size-6 has-text-success">Rp.{{ parseInt(project.paid_amount) | numFormat }}</h1>
                      </div>
                      <div class="column">
                        <b-button type="is-success" @click="showDetailHarga()">Detail Harga</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column mt-6" v-bind:class="hideAllCatatan()">
                <div class="column">
                  <p class="title is-5">Catatan Proyek</p>
                  <p class="subtitle is-7">{{ project.name }} | {{ convDate(project.start_date) }}</p>
                  <div class="columns is-mobile">
                    <div class="container is-max-desktop" v-if="project.project_notes !== null">
                      <div class="column is-full">
                        <b-input rows="4" maxlength="200" type="textarea" :value="project.project_notes" disabled></b-input>
                      </div>
                    </div>
                    <div class="container is-max-desktop" v-if="project.project_notes === null">
                      <div class="column is-full" v-bind:class="getClass()">
                        <b-input placeholder="Tuliskan catatan-catatan yang berhubungan dengan proyek disini.Seperti komplain customer, kejadian mencolok, catatan untuk proyek selanjutnya, dll."
                          v-model="notesProyek" rows="4" maxlength="200" type="textarea" required></b-input>
                        <b-button class="is-success" @click="saveNotes(project.id)" label="Simpan"></b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- WORKER ASSIGN -->
    <div class="card" v-show="activeTab === 1">
      <div>
        <sign-worker-manual v-if="project !== null" v-bind:dataProject='project'></sign-worker-manual>
      </div>
    </div>

    <!-- UPLOAD BUKTI PENGERJAAN -->
    <div class="card" v-show="activeTab === 2">
      <div>
        <upload-before-after v-if="project !== null" v-bind:project='project'></upload-before-after>
      </div>
    </div>

    <!-- ATTENDANCE WORKER -->
    <div class="card" v-show="activeTab === 4">
      <div>
        <attendance-worker/>
      </div>
    </div>

    <!-- RATING WORKER -->
    <div class="card" v-show="activeTab === 3">
      <div>
        <rating-worker v-if="project !== null"
          v-bind:projectIds="project.id"
          v-bind:vendorIds="project.vendor_id"
          v-bind:statusProject="project.status"/>
      </div>
    </div>

    <!-- MODAL DETAIL HARGA -->
    <div v-if="project !== null">
      <b-modal
        v-model="modalHargaDetail"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal>
        <div class="modal-card" style="width: 100%;">
          <header class="modal-card-head">
            <h6 style="font-weight: bold">Detail Harga</h6>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <table class="table is-fullwidth is-bordered is-striped is-narrow">
                  <tr class="has-text-weight-bold is-size-7">
                    <td>Tanggal Pengerjaan</td>
                    <td>Keahlian</td>
                    <td>Sesi</td>
                    <td>Jumlah Tukang</td>
                    <td>Harga Pertukang</td>
                    <td class="has-text-centered">Total Harga</td>
                  </tr>
                  <tbody v-for="(value, ps) in project.schedule" :key="ps">
                    <tr v-for="(value2, ps2) in value.detail_sesi" :key="ps2">
                      <td class="is-size-7">{{ convDate(value.date_sesi) }}</td>
                      <td>
                        <div class="columns is-vcentered">
                          <div class="column is-one-quarter">
                            <p class="image is-32x32">
                              <img :src="value2.dataWorker.image_uri" />
                            </p>
                          </div>
                          <div class="column is-full">
                            <span class="has-text-right is-size-7">{{ value2.dataWorker.name }}</span>
                          </div>
                        </div>
                      </td>
                      <td class="px-4 py-1 is-size-7">{{ value2.name }} ({{ value2.start_hour }} - {{ value2.end_hour }})</td>
                      <td class="is-size-7 has-text-centered">{{ value2.dataWorker.worker_assigned }}</td>
                      <td class="is-size-7 has-text-centered">Rp. {{ value2.price | numFormat }}</td>
                      <td class="is-size-7 has-text-centered">Rp. {{ (value2.price * value2.dataWorker.worker_assigned) | numFormat }}</td>
                    </tr>
                  </tbody>
                  <tr>
                    <td colspan="5" class="has-text-right is-size-7"><b>Sub Total</b></td>
                    <td class="px-5 is-size-7 has-text-centered"><span>Rp. {{ ((project.paid_amount - project.fee_charge_building) + +project.pph) | numFormat }}</span></td>
                  </tr>
                  <tr>
                    <td colspan="5" class="has-text-right is-size-7"><b>Pph (2%)</b></td>
                    <td class="px-5 is-size-7 has-text-centered"><span>(-) Rp. {{ project.pph | numFormat }}</span></td>
                  </tr>
                  <tr>
                    <td colspan="5" class="has-text-right is-size-7"><b>Total Setelah PPh</b></td>
                    <td class="px-5 is-size-7 has-text-centered"><span>Rp. {{ (project.paid_amount - project.fee_charge_building) | numFormat }}</span></td>
                  </tr>
                  <tr>
                    <td colspan="5" class="has-text-right is-size-7"><b>Biaya Parkir</b></td>
                    <td class="px-5 is-size-7 has-text-centered"><span>(+) Rp. {{ project.fee_charge_building | numFormat }}</span></td>
                  </tr>
                  <tr>
                    <td colspan="5" class="has-text-right is-size-7"><b>Total</b></td>
                    <td class="px-5 is-size-7 has-text-centered"><span>Rp. {{ project.paid_amount | numFormat }}</span></td>
                  </tr>
                </table>
              </div>
            </div>
            <hr style="border-top: 1px solid #cecece;"/>
          </section>
        </div>
      </b-modal>
    </div>

  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
// import loadash from 'lodash'
import { mapGetters } from 'vuex'
import guard from '../../routerGuard'
// import product from '../../api/product'
import SignWorkerManual from './WorkerSign.vue'
import UploadBeforeAfter from './BeforeAfter.vue'
import RatingWorker from '../worker/RatingWorker.vue'
import AttendanceWorker from '../worker/AttendanceWorker.vue'

export default {
  name: 'ProjectDetail',
  components: {
    SignWorkerManual,
    UploadBeforeAfter,
    AttendanceWorker,
    RatingWorker
  },
  data () {
    return {
      activeTab: 0,
      modalHargaDetail: false,
      modalReschedule: false,
      project: null,
      notesProyek: null,
      catatanAlco: null,
      catatanProyek: null,
      title: '',
      statusProj: null,
      productId: null,
      loading: true,
      errored: false,
      paymentInfo: null,
      isFullPage: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath'
    }),
    subTotal () {
      let x = 0
      this.project.schedule.forEach((row) => {
        row.detail_sesi.forEach((result) => {
          x += (result.price * result.dataWorker.total_worker)
        })
      })
      return x
    },
    listSesi () {
      const ls = []
      this.project.schedule.forEach((row) => {
        ls.push({
          dateSesi: row.date_sesi,
          detail: row.detail_sesi
        })
      })

      const g = [...new Map(ls.map(item => [item.dateSesi, item])).values()]
      return g
    },
    tagStatus () {
      return {
        'is-danger': this.project.status === 'Cancelled',
        'is-warning': this.project.status === 'Belum Dibayar',
        'is-primary': this.project.status === 'Mencari Mitra',
        'is-info': this.project.status === 'In Planning',
        'is-link': this.project.status === 'Active',
        'is-success': this.project.status === 'Completed'
      }
    }
  },
  methods: {
    setInvoice (val) {
      this.paymentInfo = val
    },
    closeWithoutRefresh () {
      this.modalHargaDetail = false
    },
    hideAllCatatan () {
      if (this.statusProj === 'Belum Dibayar') {
        return 'is-hidden-mobile'
      }
    },
    getClass () {
      if (this.statusProj === 'Active' ||
          this.statusProj === 'Completed' ||
          this.statusProj === 'Cancelled' || this.statusProj === 'Belum Dibayar') {
        return 'is-hidden-mobile'
      }
    },
    selectTab (val) {
      this.activeTab = val
    },
    cancelOrder (id) {
      if (id === null) { return }
      axios.get(this.url + '/api/v1/vendor/project_detail/' + id + '/cancel')
        .then((cb) => {
          this.getDetails(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Cancel Order Berhasil!',
            position: 'is-bottom',
            type: 'is-success'
          })
        }).catch(() => {
          this.getDetails(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Error Cancel Order!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.clearInput()
        })
    },
    buktiPengerjaan (v) { this.$router.push('/project/before-after/' + v.id) },
    roleAccess (role) {
      if (this.roles === null) { return true }

      let roleCheck = false
      if (this.roles !== role) {
        roleCheck = true
      }
      return roleCheck
    },
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    },
    clearInput () {
      this.notesProyek = null
    },
    clearCatatanAlco () {
      this.catatanProyek = null
    },
    showDetailHarga () {
      this.viewmodal(1)
    },
    viewmodal (newVal) {
      if (newVal === 1) {
        this.modalHargaDetail = true
      }

      if (newVal === 2) {
        this.modalReschedule = true
      }
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    back () {
      this.$router.push({ name: 'ProjectList' })
    },
    getDetails (id) {
      axios.get(`${this.url}/api/v1/vendor/project_detail_sesi?project_id=${id}`)
        .then((cb) => {
          const loadingComponent = this.$buefy.loading.open({
            container: this.isFullPage ? null : this.$refs.element
          })
          setTimeout(() => {
            if (cb.data.code === 1) {
              loadingComponent.close()

              this.project = cb.data.data[0]
              this.statusProj = cb.data.data[0].status
              this.productId = cb.data.data[0].product_id

              const data = {
                projectId: cb.data.data[0].id,
                companyName: cb.data.data[0].company_name,
                vendorId: cb.data.data[0].vendor_id
              }
              this.$store.dispatch('attendance/attendancesHomecare', data)
            }
          }, 3 * 1000)
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
    },
    saveNotes (id) {
      if (this.notesProyek === null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'data tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        this.clearInput()
        return
      }
      const data = {
        notes: this.notesProyek
      }
      axios.put(this.url + '/api/v1/vendor/project_detail/' + id + '/notes', data)
        .then(() => {
          this.getDetails(this.$route.params.projectId)
          this.$buefy.toast.open({
            duration: 1000,
            message: 'Simpan berhasil !...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.isShow = false
          this.clearInput()
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 1000,
            message: 'Error save',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.clearInput()
        })
    }
  },
  mounted () {},
  created () {
    guard.check(this.$router, this.$router.currentRoute.path)
    this.getDetails(this.$route.params.projectId)
    this.title = this.$route.params.source
  }
}
</script>

<style lang="scss" scoped>

/* Solid border */
hr.solid {
  border-top: 3px solid #bbb;
}

.back-btn {
  cursor: pointer;
}
.dash-container {
  padding: 20px 0px 0px 0px;
}
.line-item {
  margin-bottom: 10px;
}
.line-group {
  margin-bottom: 25px;
}

.is-horizontal-center {
  justify-content: center;
}

.garis_verikal {
  border-left: 1px #f1f2f2 solid;
  height: 15rem;
  width: 0px;
}

.is-hidden-mobile {
  display: none;
}
</style>
